import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
      <div>Copyright © 2024<br/>Site built with React JS</div>
    </div>
  );
}

export default Footer
