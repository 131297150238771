import React from "react";

const Genaric = (props) => {
  return (
    <div>
      <h1>{props.pageTitle}</h1>
      <p>Genaric content here</p>
    </div>
  );
};

export default Genaric;
